<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />
    <!-- 代理点信息 -->
    <div
      v-if="agentInfo"
      class="info-card"
    >
      <b-dropdown
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :text="agentInfo.name || userInfo.first_name + userInfo.last_name"
        variant="flat-primary"
      >
        <b-dropdown-item @click="handleLogout">
          退出登录
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row align-items-start">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BLink, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      agentInfo: {},
      userInfo: {},
    }
  },
  created() {
    this.handleAgentInfo()
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  methods: {
    async handleAgentInfo() {
      try {
        const value = localStorage.getItem('userInfo')
        const userInfo = JSON.parse(value)
        this.userInfo = userInfo
        const { data } = await this.$api.agentInfo({ agent_id: userInfo.id })
        this.agentInfo = data
        let newNavMenus = [].concat(this.navMenuItems)
        // 代理点
        if (userInfo.role === 1) {
          if (userInfo.type === 2) {
            newNavMenus = newNavMenus.filter(item => item.route === 'seatransnewpick' || item.route === 'accountsettings' || item.title === '统计')
            newNavMenus.forEach(item => {
              if (item.children) {
              // eslint-disable-next-line
              item.children = item.children.filter(child => child.route === 'selfstatistics')
              }
            })
            this.$router.push({ name: 'seatransnewpick' })
          } else if (userInfo.type === 1) {
            newNavMenus = newNavMenus.filter(item => item.route === 'agentpre' || item.route === 'shipmentlist' || item.title === '统计' || item.route === 'accountsettings')
            newNavMenus.forEach(item => {
              if (item.children) {
              // eslint-disable-next-line
              item.children = item.children.filter(child => child.route === 'statistics')
              }
            })
            this.$router.push({ name: 'agentpre' })
          } else if (userInfo.type === 3) {
            newNavMenus = newNavMenus.filter(item => item.route === 'seatransnewpick' || item.route === 'accountsettings' || item.title === '统计' || item.route === 'agentpre' || item.route === 'shipmentlist')
            this.$router.push({ name: 'agentpre' })
          }
        // 管理员
        } else if (userInfo.role === 2) {
          newNavMenus = newNavMenus.filter(item => item.route === 'adminstatistics')
          this.$router.push({ name: 'adminstatistics' })
        }
        this.navMenuItems = newNavMenus
      } catch (error) {
        console.log(error)
      }
    },
    handleLogout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userInfo')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.info-card{
  padding: 15px;
  margin: 0 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: center;
  .btn{
    font-size: 18px;
  }
}
.main-menu .main-menu-content{
  height: calc(100% - 4.45rem - 90px) !important;
}
</style>
