export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: '提交运单',
        route: 'agentpre',
        icon: 'HomeIcon',
      },
      {
        title: '运单列表',
        route: 'shipmentlist',
        icon: 'HomeIcon',
      },
      {
        title: '海运包裹',
        icon: 'HomeIcon',
        children: [
          {
            title: '取件',
            route: 'seatranspick',
          },
          {
            title: '入库',
            route: 'seatransput',
          },
          {
            title: '列表',
            route: 'seatranslist',
          },
        ],
      },
      {
        title: '往期账单',
        route: 'billlist',
        icon: 'HomeIcon',
      },
    ],
  },
]
