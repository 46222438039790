export default [
  {
    title: '提交运单',
    route: 'agentpre',
    icon: 'ArrowUpIcon',
  },
  // {
  //   title: '批量处理',
  //   route: 'batch',
  //   icon: 'ChevronsRightIcon',
  // },
  {
    title: '运单列表',
    route: 'shipmentlist',
    icon: 'AlignJustifyIcon',
  },
  // {
  //   title: '海运包裹',
  //   icon: 'ArchiveIcon',
  //   children: [
  //     {
  //       title: '取件',
  //       route: 'seatranspick',
  //     },
  //     {
  //       title: '入库',
  //       route: 'seatransput',
  //     },
  //     {
  //       title: '列表',
  //       route: 'seatranslist',
  //     },
  //   ],
  // },
  {
    title: '海运取件',
    route: 'seatransnewpick',
    icon: 'BoxIcon',
  },
  {
    title: '管理员统计',
    route: 'adminstatistics',
    icon: 'ActivityIcon',
  },
  {
    title: '统计',
    icon: 'ActivityIcon',
    children: [
      {
        title: '代理点统计',
        route: 'statistics',
      },
      {
        title: '海运统计',
        route: 'selfstatistics',
      },
    ],
  },
  // {
  //   title: '往期账单',
  //   route: 'billlist',
  //   icon: 'FileTextIcon',
  // },
  {
    title: '账户设置',
    route: 'accountsettings',
    icon: 'UserIcon',
  },
  // {
  //   title: '物流设置',
  //   route: 'logisticssettings',
  //   icon: 'TruckIcon',
  // },
]
