<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <div
        class="switch-old"
        @click="handleGoOldLink"
      >
        <feather-icon
          icon="NavigationIcon"
          size="18"
          class="switch-icon"
        />
        <span>切换旧版</span>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ store }}
            </p>
          </div>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="handleLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>退出登录</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,

    // Navbar Components
    // DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      storeMap: {
        1: '澳洲仓库',
        2: '中国仓库',
        3: '马来西亚仓库',
        5: '台湾仓库',
      },
      store: '',
    }
  },
  created() {
    this.initStore()
  },
  methods: {
    handleGoOldLink() {
      window.open('https://dashboard.ebaoguo.com')
    },
    async initStore() {
      try {
        const { data } = await this.$api.getUser()
        localStorage.setItem('userInfo', JSON.stringify(data))
        this.store = this.storeMap[Number(data.stores)]
      } catch (error) {
        console.log('error', error)
      }
    },
    handleLogout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userInfo')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-name{
  font-size: 18px;
}
.switch-old{
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color: #FFAF2A;
    text-decoration: underline;
  }
}
</style>
